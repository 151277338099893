import logo from './assets/sh-logo-nobg.png';
import grill from "./assets/herogrill.png";
import { Grid } from '@mui/material';
import './App.css';

function App() {

  return (
    <div className="App">
      <Grid container xs={12} style={{ 
      backgroundImage: `url(${grill})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '100vh',
      color: 'white'
    }}>
        <Grid container style={{marginTop: 50}}>
          <Grid xs={6} sm={5} md={5} item sx={{justifyContent:'center', display: 'flex', alignItems:'center', height: 50}} >
              <img src={logo} alt="logo" style={{height: 50}}></img>
              <Grid sx={{pl: 2, fontSize: {xs:30, sm:40}}}>STAKEHAUS</Grid>
              
          </Grid> 
          <Grid item xs={1} sm={2} md={4} ></Grid>
          <Grid item xs={4} sm={4} md={2} sx={{height: '50px', border: 'solid white 1px', borderRadius: 10, justifyContent:'center', display: 'flex', alignItems:'center', fontSize: 20}}>
            App Coming Soon
          </Grid>
        </Grid>
        
        <Grid container>
          <Grid item xs={12} md={9}></Grid>
          <Grid item xs={1} sx={{display: {xs:'flex', sm: 'none'}}}></Grid>
          <Grid item xs={10} md={2} sx={{fontSize: {xs: 32, md: 50}, display: {xs: 'flex', md: 'block'}, alignSelf: {xs: 'flex-end', md: 'flex-start'}, justifyContent  : {xs: 'center'}, mb:{xs:10}, lineHeight: 1.0}} >
              A balanced diet for your portfolio
          </Grid>
           
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
